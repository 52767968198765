/*import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    makeStyles,
    Slide
} from "@material-ui/core";
import clsx from "clsx";
import red from "@material-ui/core/colors/red";
//import SessionTimeout from './SessionTimeout';

const useStyles = makeStyles(() => ({
    dialog: {
        borderRadius: 0
    },
    button: {
        borderRadius: 0,
        textTransform: "none",
        padding: 5
    },
    logout: {
        color: "#fff",
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700]
        }
    },
    countdown: {
        color: red[700]
    }
}));
// imported styles..... We will have to adjust the syles we are using in the application.

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SessionTimeoutDialog = ({ open, countdown, onLogout, onContinue }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.dialog }}
            TransitionComponent={Transition}
        >
            <DialogTitle>
                Session Timeout
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2">
                    The current session is about to expire in{" "}
                    <span className={classes.countdown}>{countdown}</span> seconds.
                </Typography>
                <Typography variant="body2">{`Would you like to continue the session?`}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onLogout}
                    variant="contained"
                    className={clsx(classes.logout, classes.button)}
                >
                    Logout
                </Button>
                <Button
                    onClick={onContinue}
                    color="primary"
                    variant="contained"
                    className={classes.button}
                >
                    Continue Session
                </Button>
            </DialogActions>
        </Dialog>
    );
}


export default SessionTimeoutDialog;

//Previous code for backup

*/

import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    DialogContentText,
    makeStyles,
    Slide
} from "@material-ui/core";
//import clsx from "clsx";
import red from "@material-ui/core/colors/red";

const useStyles = makeStyles(() => ({
    dialog: {
        borderRadius: 15,
        borderWidth: "10",
        borderColor: "#00FF7F",
        backgroundColor: "#d9d9d9"
    },
    countdown: {
        color: red[700]
    }
}));
// imported styles..... We will have to adjust the syles we are using in the application.

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SessionTimeoutDialog = ({ open, countdown, onLogout, onContinue }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.dialog }}
            //classes={classes.dialog}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogTitle id="alert-dialog-title"><font color="#FF4500"><b>{"Session Timedout"}</b></font></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <font color="#000000">
                        The current session is about to expire in{" "}
                        <span className={classes.countdown}>{countdown}</span> seconds.

                        Would you like to continue the session?
                    </font>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={onLogout}
                    variant="contained"
                    color="secondary"
                    style={{ backgroundColor: "#ff8533", borderColor: "#c82333", boxShadow: "0px 1px 2px 2px #C0C0C0" }}
                    borderRadius="10"
                >
                    Logout
                </Button>
                <Button
                    autoFocus
                    onClick={onContinue}
                    variant="contained"
                    style={{ backgroundColor: "#ff8533", borderColor: "#c82333", boxShadow: "0px 1px 2px 2px #C0C0C0", borderWidth: "3" }}
                    color="primary"
                    borderRadius="10"
                >
                    Continue Session
                </Button>
            </DialogActions>
        </Dialog >
    );
}


export default SessionTimeoutDialog;
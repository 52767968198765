const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://xjslz2qimbhq5avhrk2ifgjdau.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-oam5e325xzhmjdvh7olwnmqyjq",
    "aws_content_delivery_bucket": "resaleequipmentporta-20200911115858-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1m74jmbxqkc18.cloudfront.net",
    "aws_user_files_s3_bucket": "excess-equip-file-storage113359-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ff764743-f89a-4590-baf5-1d6d6a78c8d6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_uwJ9UvY0d",
    "aws_user_pools_web_client_id": "smedgicrg2epjlcirr0gtgkn0",
    "oauth": {
        "domain": "devmobility.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid"
        ],
        "redirectSignIn": "https://excessequipment-dev.onegfapps.com/",
        "redirectSignOut": "https://excessequipment-dev.onegfapps.com/",
        "responseType": "token"
    }
};


export default awsmobile;

import React, {useState} from 'react';
import { NavLink } from "react-router-dom";
import { Card, CardDeck, Alert } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ButtonM from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
//import { NavLink } from 'react-router-dom';
//import axios from 'axios';

import Aux from '../../hoc/Auxilliary/Auxilliary';
import LocationLogo from '../../assets/location.jpg';
import Userlogo from '../../assets/usermanagement.png';
import BrokerLogo from '../../assets/broker.png'
import AlertModal from './../../components/UI/modals/alertModal';
//amplify conf
//import { API, graphqlOperation } from 'aws-amplify';

const SetupHome = (props) => {
    const useStyles = makeStyles((theme) => ({
        root: {
          flexGrow: 1,          
          display:"flex"
        },
        paper: {
          height: 100,
          width: 100,
        },
        control: {
          padding: theme.spacing(2),
        },
        cardDeck: {
            align:"center", 
            //margin:"5% 5%"
            marginTop:"5%",
            marginRight:"5%",
            marginBottom:"5%",
            marginLeft:"5%",
            // display:"flex",
            // flexWrap:"wrap",
            flex:"auto",
            justifyContent: "center",

        },
        card: {
            borderRadius: "8px",
            //boxShadow: "6px 8px 8px 8px #C0C0C0",
            boxShadow: "6px 6px 6px 6px #E0E0E0",//rgba(60, 61, 65, 0.05)",
            //padding:"1em",
            textAlign: "center",
            //width: "257px"
            border:"0px solid rgba(0,0,0,.125)",
            //height:"360px",
            //width: "85%",
            minWidth:"auto",
            marginBottom:"15px !important",
            //justifyContent: "center",

        },
        cardActionArea: {
            align:"center",
            marginTop:"10px !important",
            marginBottom:"50px"
        },
        cardAction: {
            alignSelf: "center",
            position:"absolute",
            bottom:"0"
        },
        image: {
            borderRadius: "20%",
            width:"100px"
        },
      }));

      const classes = useStyles();
      const [alertModal, setAlertModal] = useState(false)
      const [error, setError] = useState(null)
      //console.log("Home Component imported")

    //   const getUserRoles = async() => {
    //     console.log("props.user=>",props.user);
    //     try {
    //         const q = `query 
    //         {
    //             listExcessuserroless(limit:${process.env.REACT_APP_USERROLES_TABLE_LIMIT}, filter: {id: {eq: "${props.user}" }}) {
    //                 items {
    //                 role
    //                 }
    //             }
    //         }`;
    //         const apiData =await API.graphql(graphqlOperation(q))
    //         const results = apiData.data.listExcessuserroless.items;            
    //         console.log("data fetched")
    //         return results
    //     } catch (error) {
    //         const reqBody = {
    //             level:"error",
    //             message: "Error while fetching the user roles data in home component. error: "+JSON.stringify(error)
    //         }
    //         axios.post( `${process.env.REACT_APP_REST_API_END_POINT}logtotable`,
    //                         reqBody,
    //                         { headers: {'x-api-key':process.env.REACT_APP_REST_API_KEY} }
    //                     );
    //         setError("Error while fetching the user roles data")            
    //     }
    // }
    // const checkUserRole = async (path) => {
    //     let userRole = await getUserRoles()
    //     console.log("userRole home=>",userRole)
    //     if(userRole){
    //         if(userRole.length !== 0 && userRole[0].role === 'Admin'){
    //             window.location.href = path
    //        }else{
    //            setAlertModal(true)
    //            //alert("You do not have rights to access this page")
    //        }
    //     }
    // }
    return(
        <Aux>
            <Alert color="danger" isOpen={error}>{error}</Alert>
            <AlertModal open={alertModal} msg="You do not have rights to access this page.!" handler={setAlertModal}/>
            <Grid container className={classes.root}>
                <CardDeck className={classes.cardDeck}>
                   <Card className={classes.card}>
                        <NavLink style={{ textDecoration: 'none',color: 'black' }} to='/usermgt' exact>
                        <CardActionArea className={classes.cardActionArea}>
                            <span>
                                <img src={Userlogo} alt="dashboard logo" className={classes.image} />
                            </span>
                            <CardContent>
                            <Typography gutterBottom variant="h4" component="h2">
                                User Management
                            </Typography>
                            <Typography variant="inherit" color="textSecondary" component="h5">
                                Add or remove roles to a user
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        </NavLink>
                        <CardActions className={classes.cardAction}>
                            <NavLink style={{ textDecoration: 'none'}} to='/usermgt' exact>
                            <ButtonM  size="large"  style={{color:"#337ab7"}}>
                                <h5><b>Manage User</b></h5>
                            </ButtonM>
                            </NavLink>
                        </CardActions>
                    </Card>
                    <Card className={classes.card}>
                        <NavLink style={{ textDecoration: 'none',color: 'black' }} to='/locations' exact>
                        <CardActionArea className={classes.cardActionArea}>
                            <span>
                                <img src={LocationLogo} alt="user logo" className={classes.image} />
                            </span>
                            <CardContent>
                            <Typography gutterBottom variant="h4" component="h2">
                                Location Setup
                            </Typography>
                            <Typography variant="inherit" color="textSecondary" component="h5">
                                Create, edit and delete Locations
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        </NavLink>
                        <CardActions className={classes.cardAction}>
                            <NavLink style={{ textDecoration: 'none'}} to='/locations' exact>
                            <ButtonM  size="large"  style={{color:"#337ab7"}}>
                                <h5><b>Manage Locations</b></h5>
                            </ButtonM>
                            </NavLink>
                        </CardActions>
                    </Card>  
                    <Card className={classes.card}>
                        <NavLink style={{ textDecoration: 'none',color: 'black' }} to='/brokers' exact>
                        <CardActionArea className={classes.cardActionArea} >
                            <span>
                                <img src={BrokerLogo} alt="user logo" className={classes.image} />
                            </span>
                            <CardContent>
                            <Typography gutterBottom variant="h4" component="h2">
                                Broker Setup
                            </Typography>
                            <Typography variant="inherit" color="textSecondary" component="h5">
                                Create, edit and delete Brokers
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        </NavLink>
                        <CardActions className={classes.cardAction}>
                            <NavLink style={{ textDecoration: 'none'}} to='/brokers' exact>
                            <ButtonM  size="large"  style={{color:"#337ab7"}} >
                                <h5><b>Manage Brokers</b></h5>
                            </ButtonM>
                            </NavLink>
                        </CardActions>
                    </Card>                            
                </CardDeck>            
            </Grid>
        </Aux>
    );
}
export default SetupHome;
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createExcesstransactions = /* GraphQL */ `
  mutation CreateExcesstransactions(
    $input: CreateExcesstransactionsInput!
    $condition: ModelexcesstransactionsConditionInput
  ) {
    createExcesstransactions(input: $input, condition: $condition) {
      id
      transactionid
      transactiontype
      toolid
      date
      approved
      toolstatus
      location
      wafersize
      initiator
      reviewer
      reviewerremarks
      fabsection
      toolavailabledate
      lastUpdatedBy
      processed
      txnfab
      txnmodule
      txnuemseqptid
      txnuniquesutlid
      txn_assets_mgt_status
      txn_uems_remarks
      linked_to_deal
      createdAt
      updatedAt
    }
  }
`;
export const updateExcesstransactions = /* GraphQL */ `
  mutation UpdateExcesstransactions(
    $input: UpdateExcesstransactionsInput!
    $condition: ModelexcesstransactionsConditionInput
  ) {
    updateExcesstransactions(input: $input, condition: $condition) {
      id
      transactionid
      transactiontype
      toolid
      date
      approved
      toolstatus
      location
      wafersize
      initiator
      reviewer
      reviewerremarks
      fabsection
      toolavailabledate
      lastUpdatedBy
      processed
      txnfab
      txnmodule
      txnuemseqptid
      txnuniquesutlid
      txn_assets_mgt_status
      txn_uems_remarks
      linked_to_deal
      createdAt
      updatedAt
    }
  }
`;
export const deleteExcesstransactions = /* GraphQL */ `
  mutation DeleteExcesstransactions(
    $input: DeleteExcesstransactionsInput!
    $condition: ModelexcesstransactionsConditionInput
  ) {
    deleteExcesstransactions(input: $input, condition: $condition) {
      id
      transactionid
      transactiontype
      toolid
      date
      approved
      toolstatus
      location
      wafersize
      initiator
      reviewer
      reviewerremarks
      fabsection
      toolavailabledate
      lastUpdatedBy
      processed
      txnfab
      txnmodule
      txnuemseqptid
      txnuniquesutlid
      txn_assets_mgt_status
      txn_uems_remarks
      linked_to_deal
      createdAt
      updatedAt
    }
  }
`;
export const createExcessgeneral = /* GraphQL */ `
  mutation CreateExcessgeneral(
    $input: CreateExcessgeneralInput!
    $condition: ModelexcessgeneralConditionInput
  ) {
    createExcessgeneral(input: $input, condition: $condition) {
      id
      transactionid
      vendorsupplier
      model
      vintage
      serialno
      assetdescription
      softwareversion
      cim
      process
      processed
      createdAt
      updatedAt
    }
  }
`;
export const updateExcessgeneral = /* GraphQL */ `
  mutation UpdateExcessgeneral(
    $input: UpdateExcessgeneralInput!
    $condition: ModelexcessgeneralConditionInput
  ) {
    updateExcessgeneral(input: $input, condition: $condition) {
      id
      transactionid
      vendorsupplier
      model
      vintage
      serialno
      assetdescription
      softwareversion
      cim
      process
      processed
      createdAt
      updatedAt
    }
  }
`;
export const deleteExcessgeneral = /* GraphQL */ `
  mutation DeleteExcessgeneral(
    $input: DeleteExcessgeneralInput!
    $condition: ModelexcessgeneralConditionInput
  ) {
    deleteExcessgeneral(input: $input, condition: $condition) {
      id
      transactionid
      vendorsupplier
      model
      vintage
      serialno
      assetdescription
      softwareversion
      cim
      process
      processed
      createdAt
      updatedAt
    }
  }
`;
export const createExcesshardwarefabconfig = /* GraphQL */ `
  mutation CreateExcesshardwarefabconfig(
    $input: CreateExcesshardwarefabconfigInput!
    $condition: ModelexcesshardwarefabconfigConditionInput
  ) {
    createExcesshardwarefabconfig(input: $input, condition: $condition) {
      id
      transactionid
      systemtype
      description
      quantity
      status
      processed
      createdAt
      updatedAt
    }
  }
`;
export const updateExcesshardwarefabconfig = /* GraphQL */ `
  mutation UpdateExcesshardwarefabconfig(
    $input: UpdateExcesshardwarefabconfigInput!
    $condition: ModelexcesshardwarefabconfigConditionInput
  ) {
    updateExcesshardwarefabconfig(input: $input, condition: $condition) {
      id
      transactionid
      systemtype
      description
      quantity
      status
      processed
      createdAt
      updatedAt
    }
  }
`;
export const deleteExcesshardwarefabconfig = /* GraphQL */ `
  mutation DeleteExcesshardwarefabconfig(
    $input: DeleteExcesshardwarefabconfigInput!
    $condition: ModelexcesshardwarefabconfigConditionInput
  ) {
    deleteExcesshardwarefabconfig(input: $input, condition: $condition) {
      id
      transactionid
      systemtype
      description
      quantity
      status
      processed
      createdAt
      updatedAt
    }
  }
`;
export const createExcesshardwaresubfabconfig = /* GraphQL */ `
  mutation CreateExcesshardwaresubfabconfig(
    $input: CreateExcesshardwaresubfabconfigInput!
    $condition: ModelexcesshardwaresubfabconfigConditionInput
  ) {
    createExcesshardwaresubfabconfig(input: $input, condition: $condition) {
      id
      transactionid
      description
      quantity
      status
      processed
      createdAt
      updatedAt
    }
  }
`;
export const updateExcesshardwaresubfabconfig = /* GraphQL */ `
  mutation UpdateExcesshardwaresubfabconfig(
    $input: UpdateExcesshardwaresubfabconfigInput!
    $condition: ModelexcesshardwaresubfabconfigConditionInput
  ) {
    updateExcesshardwaresubfabconfig(input: $input, condition: $condition) {
      id
      transactionid
      description
      quantity
      status
      processed
      createdAt
      updatedAt
    }
  }
`;
export const deleteExcesshardwaresubfabconfig = /* GraphQL */ `
  mutation DeleteExcesshardwaresubfabconfig(
    $input: DeleteExcesshardwaresubfabconfigInput!
    $condition: ModelexcesshardwaresubfabconfigConditionInput
  ) {
    deleteExcesshardwaresubfabconfig(input: $input, condition: $condition) {
      id
      transactionid
      description
      quantity
      status
      processed
      createdAt
      updatedAt
    }
  }
`;
export const createExcessmissingfaultyparts = /* GraphQL */ `
  mutation CreateExcessmissingfaultyparts(
    $input: CreateExcessmissingfaultypartsInput!
    $condition: ModelexcessmissingfaultypartsConditionInput
  ) {
    createExcessmissingfaultyparts(input: $input, condition: $condition) {
      id
      transactionid
      description
      quantity
      processed
      createdAt
      updatedAt
    }
  }
`;
export const updateExcessmissingfaultyparts = /* GraphQL */ `
  mutation UpdateExcessmissingfaultyparts(
    $input: UpdateExcessmissingfaultypartsInput!
    $condition: ModelexcessmissingfaultypartsConditionInput
  ) {
    updateExcessmissingfaultyparts(input: $input, condition: $condition) {
      id
      transactionid
      description
      quantity
      processed
      createdAt
      updatedAt
    }
  }
`;
export const deleteExcessmissingfaultyparts = /* GraphQL */ `
  mutation DeleteExcessmissingfaultyparts(
    $input: DeleteExcessmissingfaultypartsInput!
    $condition: ModelexcessmissingfaultypartsConditionInput
  ) {
    deleteExcessmissingfaultyparts(input: $input, condition: $condition) {
      id
      transactionid
      description
      quantity
      processed
      createdAt
      updatedAt
    }
  }
`;
export const createExcesstoolpictures = /* GraphQL */ `
  mutation CreateExcesstoolpictures(
    $input: CreateExcesstoolpicturesInput!
    $condition: ModelexcesstoolpicturesConditionInput
  ) {
    createExcesstoolpictures(input: $input, condition: $condition) {
      id
      transactionid
      systemtype
      description
      toolpicture
      toolfilename
      mimetype
      md5
      processed
      createdAt
      updatedAt
    }
  }
`;
export const updateExcesstoolpictures = /* GraphQL */ `
  mutation UpdateExcesstoolpictures(
    $input: UpdateExcesstoolpicturesInput!
    $condition: ModelexcesstoolpicturesConditionInput
  ) {
    updateExcesstoolpictures(input: $input, condition: $condition) {
      id
      transactionid
      systemtype
      description
      toolpicture
      toolfilename
      mimetype
      md5
      processed
      createdAt
      updatedAt
    }
  }
`;
export const deleteExcesstoolpictures = /* GraphQL */ `
  mutation DeleteExcesstoolpictures(
    $input: DeleteExcesstoolpicturesInput!
    $condition: ModelexcesstoolpicturesConditionInput
  ) {
    deleteExcesstoolpictures(input: $input, condition: $condition) {
      id
      transactionid
      systemtype
      description
      toolpicture
      toolfilename
      mimetype
      md5
      processed
      createdAt
      updatedAt
    }
  }
`;
export const createExcessuserroles = /* GraphQL */ `
  mutation CreateExcessuserroles(
    $input: CreateExcessuserrolesInput!
    $condition: ModelexcessuserrolesConditionInput
  ) {
    createExcessuserroles(input: $input, condition: $condition) {
      id
      email
      role
      submitlist
      approvelist
      reportlist
      createdAt
      updatedAt
    }
  }
`;
export const updateExcessuserroles = /* GraphQL */ `
  mutation UpdateExcessuserroles(
    $input: UpdateExcessuserrolesInput!
    $condition: ModelexcessuserrolesConditionInput
  ) {
    updateExcessuserroles(input: $input, condition: $condition) {
      id
      email
      role
      submitlist
      approvelist
      reportlist
      createdAt
      updatedAt
    }
  }
`;
export const deleteExcessuserroles = /* GraphQL */ `
  mutation DeleteExcessuserroles(
    $input: DeleteExcessuserrolesInput!
    $condition: ModelexcessuserrolesConditionInput
  ) {
    deleteExcessuserroles(input: $input, condition: $condition) {
      id
      email
      role
      submitlist
      approvelist
      reportlist
      createdAt
      updatedAt
    }
  }
`;
export const createExcesstransactionslogger = /* GraphQL */ `
  mutation CreateExcesstransactionslogger(
    $input: CreateExcesstransactionsloggerInput!
    $condition: ModelexcesstransactionsloggerConditionInput
  ) {
    createExcesstransactionslogger(input: $input, condition: $condition) {
      id
      level
      loggeddate
      message
      createdAt
      updatedAt
    }
  }
`;
export const updateExcesstransactionslogger = /* GraphQL */ `
  mutation UpdateExcesstransactionslogger(
    $input: UpdateExcesstransactionsloggerInput!
    $condition: ModelexcesstransactionsloggerConditionInput
  ) {
    updateExcesstransactionslogger(input: $input, condition: $condition) {
      id
      level
      loggeddate
      message
      createdAt
      updatedAt
    }
  }
`;
export const deleteExcesstransactionslogger = /* GraphQL */ `
  mutation DeleteExcesstransactionslogger(
    $input: DeleteExcesstransactionsloggerInput!
    $condition: ModelexcesstransactionsloggerConditionInput
  ) {
    deleteExcesstransactionslogger(input: $input, condition: $condition) {
      id
      level
      loggeddate
      message
      createdAt
      updatedAt
    }
  }
`;
export const createExcesslocations = /* GraphQL */ `
  mutation CreateExcesslocations(
    $input: CreateExcesslocationsInput!
    $condition: ModelexcesslocationsConditionInput
  ) {
    createExcesslocations(input: $input, condition: $condition) {
      id
      location
      enable
      createdAt
      updatedAt
    }
  }
`;
export const updateExcesslocations = /* GraphQL */ `
  mutation UpdateExcesslocations(
    $input: UpdateExcesslocationsInput!
    $condition: ModelexcesslocationsConditionInput
  ) {
    updateExcesslocations(input: $input, condition: $condition) {
      id
      location
      enable
      createdAt
      updatedAt
    }
  }
`;
export const deleteExcesslocations = /* GraphQL */ `
  mutation DeleteExcesslocations(
    $input: DeleteExcesslocationsInput!
    $condition: ModelexcesslocationsConditionInput
  ) {
    deleteExcesslocations(input: $input, condition: $condition) {
      id
      location
      enable
      createdAt
      updatedAt
    }
  }
`;
export const createExcessuemsdata = /* GraphQL */ `
  mutation CreateExcessuemsdata(
    $input: CreateExcessuemsdataInput!
    $condition: ModelexcessuemsdataConditionInput
  ) {
    createExcessuemsdata(input: $input, condition: $condition) {
      id
      fab
      module
      uems_eqp_id
      unique_id
      supplier
      asset_mgt_status
      txn_uems_remarks
      createdAt
      updatedAt
    }
  }
`;
export const updateExcessuemsdata = /* GraphQL */ `
  mutation UpdateExcessuemsdata(
    $input: UpdateExcessuemsdataInput!
    $condition: ModelexcessuemsdataConditionInput
  ) {
    updateExcessuemsdata(input: $input, condition: $condition) {
      id
      fab
      module
      uems_eqp_id
      unique_id
      supplier
      asset_mgt_status
      txn_uems_remarks
      createdAt
      updatedAt
    }
  }
`;
export const deleteExcessuemsdata = /* GraphQL */ `
  mutation DeleteExcessuemsdata(
    $input: DeleteExcessuemsdataInput!
    $condition: ModelexcessuemsdataConditionInput
  ) {
    deleteExcessuemsdata(input: $input, condition: $condition) {
      id
      fab
      module
      uems_eqp_id
      unique_id
      supplier
      asset_mgt_status
      txn_uems_remarks
      createdAt
      updatedAt
    }
  }
`;
export const createExcessbrokerconfig = /* GraphQL */ `
  mutation CreateExcessbrokerconfig(
    $input: CreateExcessbrokerconfigInput!
    $condition: ModelexcessbrokerconfigConditionInput
  ) {
    createExcessbrokerconfig(input: $input, condition: $condition) {
      id
      broker
      enable
      createdAt
      updatedAt
    }
  }
`;
export const updateExcessbrokerconfig = /* GraphQL */ `
  mutation UpdateExcessbrokerconfig(
    $input: UpdateExcessbrokerconfigInput!
    $condition: ModelexcessbrokerconfigConditionInput
  ) {
    updateExcessbrokerconfig(input: $input, condition: $condition) {
      id
      broker
      enable
      createdAt
      updatedAt
    }
  }
`;
export const deleteExcessbrokerconfig = /* GraphQL */ `
  mutation DeleteExcessbrokerconfig(
    $input: DeleteExcessbrokerconfigInput!
    $condition: ModelexcessbrokerconfigConditionInput
  ) {
    deleteExcessbrokerconfig(input: $input, condition: $condition) {
      id
      broker
      enable
      createdAt
      updatedAt
    }
  }
`;
export const createExcesstoolsalestransactions = /* GraphQL */ `
  mutation CreateExcesstoolsalestransactions(
    $input: CreateExcesstoolsalestransactionsInput!
    $condition: ModelexcesstoolsalestransactionsConditionInput
  ) {
    createExcesstoolsalestransactions(input: $input, condition: $condition) {
      id
      transactionid
      transactiontype
      broker
      buyer
      date
      initiator
      l3_fab_approver
      l3_fin_approver
      status
      comment
      rejectedreason
      total_price
      total_nbv
      total_gain_loss
      nondeclared
      deal_ref
      last_updated_by
      createdAt
      updatedAt
    }
  }
`;
export const updateExcesstoolsalestransactions = /* GraphQL */ `
  mutation UpdateExcesstoolsalestransactions(
    $input: UpdateExcesstoolsalestransactionsInput!
    $condition: ModelexcesstoolsalestransactionsConditionInput
  ) {
    updateExcesstoolsalestransactions(input: $input, condition: $condition) {
      id
      transactionid
      transactiontype
      broker
      buyer
      date
      initiator
      l3_fab_approver
      l3_fin_approver
      status
      comment
      rejectedreason
      total_price
      total_nbv
      total_gain_loss
      nondeclared
      deal_ref
      last_updated_by
      createdAt
      updatedAt
    }
  }
`;
export const deleteExcesstoolsalestransactions = /* GraphQL */ `
  mutation DeleteExcesstoolsalestransactions(
    $input: DeleteExcesstoolsalestransactionsInput!
    $condition: ModelexcesstoolsalestransactionsConditionInput
  ) {
    deleteExcesstoolsalestransactions(input: $input, condition: $condition) {
      id
      transactionid
      transactiontype
      broker
      buyer
      date
      initiator
      l3_fab_approver
      l3_fin_approver
      status
      comment
      rejectedreason
      total_price
      total_nbv
      total_gain_loss
      nondeclared
      deal_ref
      last_updated_by
      createdAt
      updatedAt
    }
  }
`;
export const createExcesstoolsalestooldetails = /* GraphQL */ `
  mutation CreateExcesstoolsalestooldetails(
    $input: CreateExcesstoolsalestooldetailsInput!
    $condition: ModelexcesstoolsalestooldetailsConditionInput
  ) {
    createExcesstoolsalestooldetails(input: $input, condition: $condition) {
      id
      transactionid
      toolid
      fab
      location
      vendor
      model
      serialnum
      price
      nbv
      sales_type
      asset_mgt_status
      remarks
      no_internal_reuse
      removal_path_clear
      no_trade_in
      declared_type
      deal_ref
      createdAt
      updatedAt
    }
  }
`;
export const updateExcesstoolsalestooldetails = /* GraphQL */ `
  mutation UpdateExcesstoolsalestooldetails(
    $input: UpdateExcesstoolsalestooldetailsInput!
    $condition: ModelexcesstoolsalestooldetailsConditionInput
  ) {
    updateExcesstoolsalestooldetails(input: $input, condition: $condition) {
      id
      transactionid
      toolid
      fab
      location
      vendor
      model
      serialnum
      price
      nbv
      sales_type
      asset_mgt_status
      remarks
      no_internal_reuse
      removal_path_clear
      no_trade_in
      declared_type
      deal_ref
      createdAt
      updatedAt
    }
  }
`;
export const deleteExcesstoolsalestooldetails = /* GraphQL */ `
  mutation DeleteExcesstoolsalestooldetails(
    $input: DeleteExcesstoolsalestooldetailsInput!
    $condition: ModelexcesstoolsalestooldetailsConditionInput
  ) {
    deleteExcesstoolsalestooldetails(input: $input, condition: $condition) {
      id
      transactionid
      toolid
      fab
      location
      vendor
      model
      serialnum
      price
      nbv
      sales_type
      asset_mgt_status
      remarks
      no_internal_reuse
      removal_path_clear
      no_trade_in
      declared_type
      deal_ref
      createdAt
      updatedAt
    }
  }
`;
export const createExcesstoolsalesfilesdata = /* GraphQL */ `
  mutation CreateExcesstoolsalesfilesdata(
    $input: CreateExcesstoolsalesfilesdataInput!
    $condition: ModelexcesstoolsalesfilesdataConditionInput
  ) {
    createExcesstoolsalesfilesdata(input: $input, condition: $condition) {
      id
      transactionid
      description
      name
      mimetype
      url
      createdAt
      updatedAt
    }
  }
`;
export const updateExcesstoolsalesfilesdata = /* GraphQL */ `
  mutation UpdateExcesstoolsalesfilesdata(
    $input: UpdateExcesstoolsalesfilesdataInput!
    $condition: ModelexcesstoolsalesfilesdataConditionInput
  ) {
    updateExcesstoolsalesfilesdata(input: $input, condition: $condition) {
      id
      transactionid
      description
      name
      mimetype
      url
      createdAt
      updatedAt
    }
  }
`;
export const deleteExcesstoolsalesfilesdata = /* GraphQL */ `
  mutation DeleteExcesstoolsalesfilesdata(
    $input: DeleteExcesstoolsalesfilesdataInput!
    $condition: ModelexcesstoolsalesfilesdataConditionInput
  ) {
    deleteExcesstoolsalesfilesdata(input: $input, condition: $condition) {
      id
      transactionid
      description
      name
      mimetype
      url
      createdAt
      updatedAt
    }
  }
`;
export const createExcesstoolsalesapprovals = /* GraphQL */ `
  mutation CreateExcesstoolsalesapprovals(
    $input: CreateExcesstoolsalesapprovalsInput!
    $condition: ModelexcesstoolsalesapprovalsConditionInput
  ) {
    createExcesstoolsalesapprovals(input: $input, condition: $condition) {
      id
      transactionid
      l1
      l1_time
      l2
      l2_time
      l3
      l3_time
      l4
      l4_time
      l5
      l5_time
      l1_reminders
      l2_reminders
      l3_reminders
      l4_reminders
      rejetedlevel
      rejectedby
      cancelledby
      createdAt
      updatedAt
    }
  }
`;
export const updateExcesstoolsalesapprovals = /* GraphQL */ `
  mutation UpdateExcesstoolsalesapprovals(
    $input: UpdateExcesstoolsalesapprovalsInput!
    $condition: ModelexcesstoolsalesapprovalsConditionInput
  ) {
    updateExcesstoolsalesapprovals(input: $input, condition: $condition) {
      id
      transactionid
      l1
      l1_time
      l2
      l2_time
      l3
      l3_time
      l4
      l4_time
      l5
      l5_time
      l1_reminders
      l2_reminders
      l3_reminders
      l4_reminders
      rejetedlevel
      rejectedby
      cancelledby
      createdAt
      updatedAt
    }
  }
`;
export const deleteExcesstoolsalesapprovals = /* GraphQL */ `
  mutation DeleteExcesstoolsalesapprovals(
    $input: DeleteExcesstoolsalesapprovalsInput!
    $condition: ModelexcesstoolsalesapprovalsConditionInput
  ) {
    deleteExcesstoolsalesapprovals(input: $input, condition: $condition) {
      id
      transactionid
      l1
      l1_time
      l2
      l2_time
      l3
      l3_time
      l4
      l4_time
      l5
      l5_time
      l1_reminders
      l2_reminders
      l3_reminders
      l4_reminders
      rejetedlevel
      rejectedby
      cancelledby
      createdAt
      updatedAt
    }
  }
`;

export const headerMapping = {
    "general":"General Product Information",
    "transaction":"Transaction Information",
    "hardwarefab":"Hardware Configuration (Fab)",
    "hardwaresubfab":"Hardware Configuration (Subfab / Auxilliary Units)",
    "missingparts":"Missing/Faulty Parts / Accesorries List",
    "manufactureserial":"Manufacturer Serial Number Plate",
    "cost":"Total Estimated Cost",
    "photos":"Tool Pictures",
    "configfiles":"Additional Tool Data Files"
}

export const columnMapping = {
    "general":{
        "transactionid":"TransactionId",
        "vendorsupplier":"VendorSupplier",
        "make":"Make",
        "model":"Model",
        "process":"Process",
        "vintage":"Vintage",
        "serial":"SerialNo",
        "assetdescription":"AssetDescription",
        "softwareversion":"SoftwareVersion",
        "cim":"CIM"
    },
    "transaction":{
        "transactionid":"TransactionId",
        "transactiontype":"TransactionType",
        "toolid":"ToolID",
        "transactiondate":"Date",
        "approved":"Approved",
        "toolname":"ToolName",
        "toolstatus":"ToolStatus",
        "warehouse":"Warehouse"
    },
    "hardwarefab":{
        "transactionid":"TransactionId",
        "description":"Description",
        "quantity":"Quantity",
        "status":"Status",
        "systemtype":"SystemType"
    },
    "hardwaresubfab":{
        "transactionid":"TransactionId",
        "description":"Description",
        "quantity":"Quantity",
        "status":"Status"
    },
    "missingparts":{
        "transactionid":"TransactionId",
        "description":"Description",
        "quantity":"Quantity",
    },
    "cost":{
        "transactionid":"TransactionId",
        "totalusd":"TotalCost"
    },
    "toolphoto":{
        "transactionid":"TransactionId",
        "systemtype":"SystemType",
        "description":"Description",
        "mimetype":"MimeType",
        "md5":"md5",
        "filename":"ToolFileName",
        "toolfile":"ToolPicture"
    }
}

export const sectionalHeaderMapping = {
    "transactiontype":"Transaction Type",
    "txnfab":"Fab",
    "txnmodule":"Module",
    "txnuemseqptid":"UEMS Eqpt ID",
    "toolid":"Tool ID",
    "date":"Date",
    "approved":"Approved",
    "toolstatus":"Tool Status",
    "location":"Location",
    "wafersize":"Wafer Size",
    "initiator":"Initiator",
    "reviewer":"Reviewer",
    "reviewerremarks":"Reviewer Remarks",
    "fabsection":"Fab Section",
    "toolavailabledate":"Tool Available Date",
    "ReviewerRemarks":"Reviewer Remarks",
    "assetdescription":"Asset Description",
    "cim":"CIM",
    "model":"Model",
    "process":"Process",
    "serialno":"Serial No",
    "softwareversion":"Software Version",
    "vendorsupplier":"Vendor Supplier",
    "vintage":"Vintage",
    "systemtype":"System Type",
    "description":"Description",
    "quantity":"Quantity",
    "status":"Status"
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter } from 'react-router-dom';

// amplify config
//import Amplify, { Auth, Storage } from "aws-amplify";
//import awsExports from "./aws-exports"; 

//Amplify.configure(awsExports);
// Amplify.configure({
//     Auth: {
//       identityPoolId: "us-east-1:74f0f84e-815e-4d17-8920-70a078311a8d",
//       region: "us-east-1",
//         //"aws_user_pools_id": "us-east-1_axv3N3f7i",
//       userPoolId: "us-east-1_41GzcgZ5e",
//       //"aws_user_pools_web_client_id": "2ael8308juifqdvtcve11t913o",
//       userPoolWebClientId: "28e9a6icqpe41hckn6o2bro5v2",
//     },
//     Storage: {
//         AWSS3: {
//             bucket: 'excess-equip-file-storage121606-dev', //REQUIRED -  Amazon S3 bucket
//             region: 'us-east-1', //OPTIONAL -  Amazon service region            
//         }
//     }
// });


ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />,
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

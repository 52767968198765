import React, { useState, useEffect } from 'react';
import './App.css';
import Layout from '../src/hoc/Layout/Layout';
import ExcessEquipmentMain from './containers/ExcessEquipmentMain';
import Aux from './../src/hoc/Auxilliary/Auxilliary';
import LoginButton from './components/UI/Buttons/LoginButton/loginButton';
import AppContext from '../src/context/app-context';
//amplify conf
import Amplify, { Auth, Hub } from "aws-amplify";
// import awsExports from "./poc-aws-exports";
import awsExports from "./dev-aws-exports";
import { API, graphqlOperation } from 'aws-amplify';
// importing custom component
import LoadingModal from './components/UI/modals/loadingModal.jsx';
import SessionTimeout from './SessionTimeout';
// configuring amplify
Amplify.configure(awsExports);

function App() {
  // login user 
  const [authuser, setAuthuser] = useState(null);
  // login user role
  const [role, setRole] = useState("");
  // for loading modal
  const [loading, setLoading] = useState(false)
   //Added logic for session timeout
   const [isAuthenticated, setAuth] = useState(false);
   const handleClick = () => {
    Auth.signOut();
    setAuth(!isAuthenticated)
  }

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log("user signIn");
          setLoading(true)
          break;
        // case 'cognitoHostedUI':
        //   // console.log("user cognitoHostedUI");
        //   // getUser().then(userData => setAuthuser(userData));
        //   break;
        case 'signOut':
          setAuthuser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        default:
          break;
      }
    });    
    // function to get user role based on user email
    const getUser = async() =>  {
      return await Auth.currentAuthenticatedUser()
        .then(async userData => {
          setLoading(true)
            try {
                const q = `query 
                {
                    listExcessuserroless(limit:${process.env.REACT_APP_USERROLES_TABLE_LIMIT}, filter: {id: {eq: "${userData.signInUserSession.idToken.payload.email.toLowerCase()}" }}) {
                        items {
                            role
                        }
                    }
                }`;
                const apiData =await API.graphql(graphqlOperation(q))
                const results = apiData.data.listExcessuserroless.items;            
                if(results.length!==0 ){
                    console.log("user role=>",results[0].role)
                    setRole(results[0].role)
                }            
            } catch (error) {
              console.error("Error in user role")
            }
            if (userData) {
              setAuthuser(userData.signInUserSession.idToken.payload.email.toLowerCase());
              setLoading(false)
              setAuth(!isAuthenticated)
            }
          }
        )
        .catch(() => console.log('Not signed in'));
    }
    getUser()
    
  }, []);

  
  // function to trigger on-click of Login button
  function authClick() {
    if(process.env.NODE_ENV==="development"){
      //local
        // window.location.assign('https://mobilityais.auth.us-east-1.amazoncognito.com/login?client_id=28e9a6icqpe41hckn6o2bro5v2&response_type=token&scope=email+openid&redirect_uri=http://localhost:3000/');
    }
    else{
      //poc url
      // window.location.assign(`https://mobilityais.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=ADFS&redirect_uri=${awsExports.oauth.redirectSignIn}&response_type=TOKEN&client_id=${awsExports.aws_user_pools_web_client_id}&scope=email%20openid`);

      // development url
      window.location.assign(`https://devmobility.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=ADFS&redirect_uri=${awsExports.oauth.redirectSignIn}&response_type=TOKEN&client_id=${awsExports.aws_user_pools_web_client_id}&scope=email%20openid`);

      // production url
      //window.location.assign(`https://gfmobility.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=ADFS&redirect_uri=${awsExports.oauth.redirectSignIn}&response_type=TOKEN&client_id=${awsExports.aws_user_pools_web_client_id}&scope=email%20openid`);
    }
  }

  let returnVal = null
  console.log("authuser : ", authuser)

  if (authuser) {
    returnVal =
      <Layout>
        <AppContext.Provider value={{ user: authuser, role:role }} >
          <LoadingModal open={loading} msg="Loading..." />
          <SessionTimeout isAuthenticated={isAuthenticated} logOut={handleClick} />
          <ExcessEquipmentMain code="" />
        </AppContext.Provider>
      </Layout>
  }
  else {
    returnVal = <LoginButton clicked={authClick} />
  }

  return (
    <Aux>
      {returnVal}
    </Aux>
  );

}

export default App;
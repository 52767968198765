export const colors={
    "headerTextColor" : "#f0f0f0",
    "headerColColor" : "#545b64",
    "infoColor" : "#17a2b8",
    "warningColor" : "#fb8734",
    "dangerColor" : "#dc3545",
    "successColor" : "#0ca730",
    "draftColor" : "#2362e9",
    "cancelColor" : "#f56b53",
    "bgMenuButtonColor" :"#424242",
    "bgSuccess" : "#d4edda",
    "bgGray" : "#6c757d",
    "bgGf" : "#fb8734",
    "bgLightGray" :"#c1c1c1",
    "bgShadow" : "#E0E0E0",
}

export const headerTextColor = "#424242"
export const errorConstants = {
    "required":"This field is required",
    "charinvalid":"Input cannot contain following characters $",
    "serverreqfailed":"Request to server failed. Please resubmit the form. If problem persists, please contact technical support.",
    "missingreqfields":"One or more required fields missing values. Please fill and resubmit.",
    "errorloading":"Error loading Excess Equipment form. Please try again after some time. If problem persists, please contact technical support.",
    "getrequestfailed":"Error getting excess form from server. Please try again after some time. If problem persists, please contact technical support.",
    "norecordsfortransaction":"No records found for the transaction id: ",
    "toomanyrecords":"More than one record found for transction id: ",
    "incorrectnumber":"Invalid number",
    "notauthorized":"Error 401. You are not authorized to view this page",
    "improperresults":"Incorrect results format from server. Please try again. If problem persists, please contact technical support.",
    "unabletodelete"  : "Unable to delete the form. If problem persists, please contact technical support. ",
    "duplicatetoolid" : "This Tool ID already has an APPROVED form, please cancel your form and look under 'View Transaction'",
    "notoolerrormessage" : "Tool no longer available from UEMS. Please select available UEMS Eqpt ID"
}

export const successConstants = {
    "formsubmitted":"Excess Tools Part List & Accessories Declaration Form has been successfully submitted.",
    "formresponsesubmitted":"Your review response has been successfully submitted. The form for this transaction will become read-only.",
    "formsaved":"Excess Tools Part List & Accessories Declaration Form has been successfully saved. You can access and edit the form from home screen.",
    "formsavednote":"Note: Pictures uploaded are not saved. Please re-upload the pictures before you submit the form.",
    "reassigned":"Transaction is assigned successfully.!"
}

export const htmlConstants = {
    "header":"Excess Tools Part List & Accessories Declaration Form",
    "approvalpending":"Pending for Approval",
    "readonlyform":"This form is in VIEW ONLY mode.",
    "confirmsave":"Your form changes are not saved. All your changes would be lost. Would you like to save the form?",
    "saveinfo":"Please save the form periodically in order not to lose any data.",
    "toolpictureformat":"Tool picture/pictures should be in JPG/PNG/HEIC format.",
    "toolpicturesize":"Tool picture size should be less than 5 MB.",
    "confileformat":"Configuration file/files should be in PDF/EXCEL/DWG format. If document has graphs, maps or diagrams etc., convert as PDF and upload.",
    "confilesize":"Configuration file size should not exceed 15 MB.",
    "filename":"File name should not contain special characters like below    # ? % : / [] @ ! $ & () * + ; , . ",
    "uemsdata":'UEMS equipment data has been added, please refer to "Help Guide" for details'

}

export const generalConstants = {
    "disclaimer" : "Disclaimer : This is a Deal Approval system only, and is used to comply with GF Corporate Finance Policy 1417, OS-008173.",
    "disclaimer2" : "Actual deal data is contained in the deal documents retained by GF Finance."
}

//export const homeColumns = ["Actions","transactionid","transactiontype","toolid", "approved", "toolstatus", "location", "initiator","reviewer", "date", "toolavailabledate", "isinitiator"]
//export const homeColumnHeaders = ["Actions","Transaction Id","Transaction Type","Tool Id", "Approved", "Tool Status", "Location", "Initiator","Reviewer", "Date", "Tool Available Date", "isInitiator"]
export const homeColumns = ["Actions","transactionid","toolid", "approved", "toolstatus", "location", "initiator","reviewer", "date", "toolavailabledate", "isinitiator"]
export const homeColumnHeaders = ["Actions","Transaction Id","Tool Id", "Approved", "Tool Status", "Location", "Initiator","Reviewer", "Date", "Tool Available Date", "isInitiator"]

export const fileKeys = ["toolfilename", "toolpicture", "mimetype", "systemtype"];

export const harwareFabMappings = {
   "main":"Main System",
   "factory":"Factory Interface",
   "handler":"Handler System",
   "options":"Options System",
   "others":"Others",
   "manufactureserial":"Manufacturer Serial Number Plate"
}

export const userTableHeaders = {
   "actions":"Actions",
   "email":"User Email",
   "role":"Role",
   "submitlist":"Declaration Submit Mail",
   "approvelist":"Declaration Approve Mail",
   "reportlist":"Declaration Weekly Report",
   "sales_notification":"Sales Notification",
   "sales_week_report":"Sales Weekly Report"
}

export const locationTableHeaders = {
    "actions":"Actions",
    "location":"Location",
    "enable":"Enable"
 }

export const brokerTableHeaders = {
    "actions":"Actions",
    "broker":"Broker",
    "enable":"Enable"
}

export const toolSalesTransactionsTableHeader = {
    "actions":"Actions",    
    "deal_ref":"Deal Ref ID",
    "initiator":"Initiator",    
    "broker":"Broker",
    "buyer":"Buyer",    
    "total_price":"Total Offer Price* ($US)",
    "total_nbv":"Total NBV($US)",
    "total_gain_loss":"Total Gain/Loss($US)",
    "status":"Status",
    "date":"Date",
    "comment":"Comment"
}

export const toolDeclarationTableHeaders = {
    "actions":"Actions",
    "toolid": "Tool ID",
    "fab":"Fab",
    "location":"Location",
    "vendor":"Vendor/Supplier",
    "model":"Model",
    "serialnum":"Serial No.",
    "price":"Offer Price($US)",
    "nbv":"NBV($US)",
    "org_eqp_price" : "Original Eqpt Cost($US)",
    "price_percent" : "Offer Price/ Original Eqpt Cost(%)",
    "capitalized_date" : "Capitalization Date",
    "fin_analysis" : "Finance Analysis",
    "sales_type":"Sales Type",
    "asset_mgt_status":"Assets Mgmt Status",
    "remarks":"Remarks",
    // "no_internal_reuse":"AO confirm no Internal Reuse/Part Use",
    // "removal_path_clear":"AO confirm removal path clear/ site budget",
    // "no_trade_in":"GSM confirm no Trade-In"
    "no_internal_reuse":"AO confirm no Internal Reuse/Part Use/Trade-In"
}
export const l1ApprovalTableHeaders = {
    "toolid": "Tool ID",
    "fab":"Fab",
    "location":"Location",
    "vendor":"Vendor/Supplier",
    "model":"Model",
    "serialnum":"Serial No.",
    "price":"Offer Price($US)",
    "nbv":"NBV($US)",
    "org_eqp_price" : "Original Eqpt Cost($US)",
    "price_percent" : "Offer Price/ Original Eqpt Cost(%)",
    "capitalized_date" : "Capitalization Date",
    "fin_analysis" : "Finance Analysis"    
}
export const l2ApprovalTableHeaders = {
    "toolid": "Tool ID",
    "fab":"Fab",
    "location":"Location",
    "vendor":"Vendor/Supplier",
    "model":"Model",
    "serialnum":"Serial No.",
    "asset_mgt_status":"Assets Mgmt Status",
    "no_internal_reuse":"AO confirm no Internal Reuse/Part Use/Trade-In",
    "remarks":"Remarks"
}

export const approvalStages = {
  draft: "DRAFT",
  l1Approval: "L1 Update Pending",
  l2Approval: "L2 Update Pending",
  l3Approval: "L3 Approval Pending",
  l3FabFinApproval: "L3 Fab/Fin Approval Pending",
  l3FabApproval: "L3 Fab Approval Pending",
  l3FinApproval: "L3 Finance Approval Pending",
  l4Approval: "L4 Approval Pending",
  l5Approval: "L5 Approval Pending",
  approved: "Approved",
  cancelled: "Cancelled",
  l1reject: "L1 Rejected",
  l2reject: "L2 Rejected",
  l3reject: "L3 Rejected",
  l3FabReject: "L3 Fab Rejected",
  l3FinReject: "L3 Finance Rejected",
  l4reject: "L4 Rejected",
  l5reject: "L5 Rejected",
  "re-submit-info": "re-submit-info",
  "re-approve-price": "re-approve-price",
  "re-approve-buyer": "re-approve-buyer"
};

export const userRoles = {
    "Admin":"Admin",
    "Common Viewer":"Common Viewer",
    "Reviewer":"Reviewer",
    "Viewer":"Viewer",
    "Tool Sales Viewer":"Tool Sales Viewer",
    "Tool Sales Creator":"Tool Sales Creator",
    "Tool Sales L1 Data Provider":"Tool Sales L1 Data Provider",
    "Tool Sales L2 Data Provider":"Tool Sales L2 Data Provider",
    "Tool Sales L3 Approver":"Tool Sales L3 Approver",
    "Tool Sales L4 Approver":"Tool Sales L4 Approver",
    "Tool Sales L5 Approver":"Tool Sales L5 Approver"
}

export const toolStatusDetailsHeaders = {
    "toolid": "Tool ID",
    "deal_ref": "Linked Transaction Ref ID",
    "fab": "FAB",
    "location": "Location",
    "model": "Model",
    "serialnum": "Serial Number",
    "asset_mgt_status": "Assets Management Status",
    "price": "Price($US)",
    "nbv": "NBV($US)",        
    "sales_type": "Sales Type",    
    "vendor": "Vendor/Supplier",
    "no_internal_reuse": "AO confirm",
    "remarks":"Remarks"
}

export const toolSalesViewTxnHeaders = {
    "deal_ref": "Deal Ref Number",
    "transactiontype":"Transaction Type",
    "date": "Date",
    "broker": "Broker",
    "buyer": "Buyer",    
    "total_price": "Total Price ($US)",
    "total_nbv": "Total NBV ($US)",
    "total_gain_loss": "Total Gain/Loss ($US)",
    "status": "Status",
    "l3_fab_approver": "L3 Fab Approver",
    "l3_fin_approver": "L3 Fin Approver",
    "initiator": "Initiator",    
    "last_updated_by": "Last Updated By",
    "comment": "Comment",
    "rejectedreason" : "Rejected Reason"
}

export const toolStatusChartLabels = ["Approved", "Waiting for approval", "Rejected"]
export const toolStatusChartLabelColors= ["#00ff4b", "#ffff3d", "#ff4c00"]
export const toolStatusChartLabelHoverColors= ["#00ff8e", "#ffff78", "#ff4c3f"]

export const toolChartLabels = ['last 10 days', 'last 30 days', 'last 60 days']
export const toolChartLegend = 'Tools Submitted'

export const headerMapping = {
    "general":"General Product Information",
    "transaction":"Transaction Information",
    "hardwarefab":"Hardware Configuration (Fab)",
    "hardwaresubfab":"Hardware Configuration (Subfab / Auxilliary Units)",
    "missingparts":"Missing/Faulty Parts / Accesorries List",
    "manufactureserial":"Manufacturer Serial Number Plate",
    "cost":"Total Estimated Cost",
    "photos":"Tool Pictures",
    "configfiles":"Additional Tool Data Files"
}

export const rowMapping = {
    "toolid":"Tool ID",
    "toolstatus":"Tool Status",
    "location":"Location",
    "wafersize":"Wafer Size",
    "fabsection":"Fab Section",
    "toolavailabledate":"Tool Available Date",
    "vendorsupplier":"Vendor Supplier",
    "model":"Model",
    "vintage":"Vintage",
    "serialno":"Serial No",
    "assetdescription":"Asset Description",
    "softwareversion":"Software Version",
    "cim":"CIM",
    "process":"Process",
    "systemtype":"System Type",
    "description":"Description",
    "quantity":"Quantity",
    "status":"Status",
    "general":"General",
    "manufactureserial":"Manufacture Serial",
    "hardwarefab":"Hardware Fab",
    "hardwaresubfab":"Hardware Sub-fab",
    "missingparts":"Missing Parts",
    "configfiles":"Config Files"
}
export const formDefinition = {
    "title": "Excess Tools Part List & Accessories Declaration Form",
    "key": "transaction",
    "items": [
        {
            "label": "Transaction Type",
            "type": "hidden",
            "name": "transaction-transactiontype",
            "values": ["Retirement for Sale"]
        },
        {
            "label": "Fab",
            "type": "select",
            "name": "transaction-txnfab",
            "values": ["..."],

        },
        {
            "label": "Module",
            "type": "select",
            "name": "transaction-txnmodule",
            "values": ["..."],

        },
        {
            "label": "UEMS Eqpt ID",
            "type": "select",
            "name": "transaction-txnuemseqptid",
            "values": ["..."],

        },
        {
            "label": "Tool ID",
            "type": "text",
            "name": "transaction-toolid",

        },
        {
            "label": "Tool Status",
            "type": "select",
            "name": "transaction-toolstatus",
            "values": ["...", "Connected", "Disconnected", "In refurb", "Running Wafers", "Warehouse"],

        },
        {
            "label": "Fab Section",
            "type": "select",
            "name": "transaction-fabsection",
            "values": ["...", "Clean",  "CMP", "Defect Detection", "Diffusion", "Etch", "Failure Analysis", "Implant", "Lab", "Lithography", "Material Handling", "Metrology", "R&D", "Reliability Lab,", "Support Tools", "Test", "Thin Film"],

        },
        {
            "label": "Location",
            "type": "select",
            "name": "transaction-location",
            "values": ["...", "Dresden, Germany", "Woodlands, Singapore", "BHS Senkee, Singapore", "BHS Gul Way, Singapore", "Chasen-AC Jurong, Singapore", "Chasen-NAC Jurong, Singapore", "Malta, USA", "East Fishkill, USA", "Burlington, USA", "Albany, USA", "Taiwan", "Korea", "Japan", "Singapore", "Austria", "Netherlands"],

        },
        {
            "label": "Date",
            "type": "date",
            "name": "transaction-date",
        },
        {
            "label": "Tool Available Date",
            "type": "date",
            "name": "transaction-toolavailabledate",
        },
        {
            "label": "Wafer Size",
            "type": "select",
            "name": "transaction-wafersize",
            "values": ["...", "200 mm", "300 mm", "NA"],

        }
    ],
    "data": [
        {
            "header": "General Product Information",
            "key": "general",
            "items": [
                {
                    "label": "Vendor / Supplier",
                    "type": "text",
                    "name": "general-vendorsupplier",

                },
                {
                    "label": "Serial No.",
                    "type": "text",
                    "name": "general-serialno",

                },
                
                {
                    "label": "Model",
                    "type": "text",
                    "name": "general-model",

                },
                {
                    "label": "Process",
                    "type": "text",
                    "name": "general-process",
                },
                {
                    "label": "Estimated Vintage (in year)",
                    "type": "number",
                    "name": "general-vintage"
                },
                {
                    "label": "Asset Description",
                    "type": "text",
                    "name": "general-assetdescription"
                },
                {
                    "label": "Software Version",
                    "type": "text",
                    "name": "general-softwareversion"
                },
                {
                    "label": "CIM",
                    "type": "text",
                    "name": "general-cim",
                    "info":"SECS, GEM, NONE, etc"
                }
            ]
        },
        {
            "header": "Hardware Configuration (Fab)",
            "key": "hardwarefab",
            "items": [
                {
                    "legend": "Main System",
                    "key": "main",
                    "items": [
                        [{
                            "label": "Description",
                            "type": "text",
                            "name": "hardwarefab-main-description",

                        },
                        {
                            "label": "Quantity",
                            "type": "number",
                            "name": "hardwarefab-main-quantity"
                        },
                        {
                            "label": "Status",
                            "type": "checkbox",
                            "name": "hardwarefab-main-status",
                            "info":"(leave unchecked if not OK)"
                        }]
                    ]
                },
                {
                    "legend": "Factory Interface",
                    "key": "factory",
                    "items": [
                        [{
                            "label": "Description",
                            "type": "select",
                            "name": "hardwarefab-factory-description",
                            "values": ["...","FOUP", "Open Cassette", "SMIF", "NONE"]
                        },
                        {
                            "label": "Quantity",
                            "type": "number",
                            "name": "hardwarefab-factory-quantity"
                        },
                        {
                            "label": "Status",
                            "type": "checkbox",
                            "name": "hardwarefab-factory-status",
                            "info":"(leave unchecked if not OK)"
                        }]
                    ]
                },
                {
                    "legend": "Handler System",
                    "key": "handler",
                    "items": [
                        [{
                            "label": "Description",
                            "type": "text",
                            "name": "hardwarefab-handler-description",

                        },
                        {
                            "label": "Quantity",
                            "type": "number",
                            "name": "hardwarefab-handler-quantity"
                        },
                        {
                            "label": "Status",
                            "type": "checkbox",
                            "name": "hardwarefab-handler-status",
                            "info":"(leave unchecked if not OK)"
                        }]
                    ]
                },
                {
                    "legend": "Options System",
                    "key": "options",
                    "items": [
                        [{
                            "label": "Description",
                            "type": "text",
                            "name": "hardwarefab-options-description",

                        },
                        {
                            "label": "Quantity",
                            "type": "number",
                            "name": "hardwarefab-options-quantity"
                        },
                        {
                            "label": "Status",
                            "type": "checkbox",
                            "name": "hardwarefab-options-status",
                            "info":"(leave unchecked if not OK)"
                        }]                                           
                    ]
                },
                {
                    "legend": "Others (Pls specify if any)",
                    "key": "others",
                    "items": [
                        [{
                            "label": "Description",
                            "type": "text",
                            "name": "hardwarefab-others-description"
                        },
                        {
                            "label": "Quantity",
                            "type": "number",
                            "name": "hardwarefab-others-quantity"
                        },
                        {
                            "label": "Status",
                            "type": "checkbox",
                            "name": "hardwarefab-others-status",
                            "info":"(leave unchecked if not OK)"
                        }]
                    ]
                }
            ]
        },
        {
            "header": "Hardware Configuration (Subfab / Auxilliary Units)",
            "key": "hardwaresubfab",
            "items": [
                {
                    "legend": " ",
                    "key": "hardwaresubfab",
                    "items": [
                        [{
                            "label": "Description",
                            "type": "text",
                            "name": "hardwaresubfab-description"
                        },
                        {
                            "label": "Quantity",
                            "type": "number",
                            "name": "hardwaresubfab-quantity"
                        },
                        {
                            "label": "Status",
                            "type": "checkbox",
                            "name": "hardwaresubfab-status",
                            "info":"(leave unchecked if not OK)"
                        }]
                    ]
                }
            ]
        },
        {
            "header": "Known Missing/Faulty Parts / Accesorries List",
            "key": "missingparts",
            "items": [
                {
                    "legend": " ",
                    "key": "missingparts",
                    "items": [
                        [{
                            "label": "Description",
                            "type": "text",
                            "name": "missingparts-description"
                        },
                        {
                            "label": "Quantity",
                            "type": "number",
                            "name": "missingparts-quantity"
                        }]
                    ]
                }
            ]
        },
        {
            "header": "Photo Upload",
            "key": "photos",
            "items": [
                {
                    "legend": "General",
                    "items": [
                        [{
                            "label": "Description",
                            "type": "text",
                            "name": "photos-general-description"
                        },
                        {
                            "label": "File(s)",
                            "type": "file",
                            "accept":"image/*",
                            "name": "photos-general-files",
                            "info": "For multi image uploads, please ensure you have all images under one folder. (accepted only jpg/jpeg/png/heic)"
                        }]
                    ]
                },
                {
                    "legend": "Manufacturer Serial Number Plate",
                    "items": [
                        [{
                            "label": "Description",
                            "type": "text",
                            "name": "photos-manufactureserial-description"
                        },
                        {
                            "label": "File(s)",
                            "type": "file",
                            "accept":"image/*",
                            "name": "photos-manufactureserial-files",
                            "info": "For multi image uploads, please ensure you have all images under one folder. (accepted only jpg/jpeg/png/heic)"

                        }]
                    ]
                },
                {
                    "legend": "Hardware Configuration (Fab)",
                    "items": [
                        [{
                            "label": "Description",
                            "type": "text",
                            "name": "photos-hardwarefab-description"
                        },
                        {
                            "label": "File(s)",
                            "type": "file",
                            "accept":"image/*",
                            "name": "photos-hardwarefab-files",
                            "info": "For multi image uploads, please ensure you have all images under one folder. (accepted only jpg/jpeg/png/heic)"

                        }]
                    ]
                },
                {
                    "legend": "Hardware Configuration (Subfab / Auxilliary Units)",
                    "items": [
                        [{
                            "label": "Description",
                            "type": "text",
                            "name": "photos-hardwaresubfab-description"
                        },
                        {
                            "label": "File(s)",
                            "type": "file",
                            "accept":"image/*",
                            "name": "photos-hardwaresubfab-files",
                            "info": "For multi image uploads, please ensure you have all images under one folder. (accepted only jpg/jpeg/png/heic)"

                        }]
                    ]
                },
                {
                    "legend": "Known Missing Parts [photos showing major missing parts]",
                    "items": [
                        [{
                            "label": "Description",
                            "type": "text",
                            "name": "photos-missingparts-description"
                        },
                        {
                            "label": "File(s)",
                            "type": "file",
                            "accept":"image/*",
                            "name": "photos-missingparts-files",
                            "info": "For multi image uploads, please ensure you have all images under one folder. (accepted only jpg/jpeg/png/heic)"

                        }]
                    ]
                },
                {
                    "legend": "configfileslegend",
                    //"legend": "Additional Configuration files (if any)"
                    "items": [
                        [{
                            "label": "Description",
                            "type": "text",
                            "name": "photos-configfiles-description"
                        },
                        {
                            "label": "File(s)",
                            "type": "file",
                            "accept": ".xlsx, .xls, .doc, .docx, .pdf, .dwg",
                            "name": "photos-configfiles-files",
                            "info": "Upload configuration as file (PDF/Excel/DWG). File Size should not exceed 15 MB"

                        }]
                    ]
                }/*
                {
                    "legend": "configuration files",
                    //"key": "configfiles",
                    "items": [
                        [{
                            "label": "Description",
                            "type": "text",
                            "name": "photos-configfiles-description"
                        },
                        {
                            "label": "File",
                            "type": "file",
                            "accept": ".xlsx, .xls, .doc, .docx, .pdf",
                            "name": "photos-configfiles-files",
                            "info": "Upload configuration as file (Word/PDF/Excel)."
                        }]
                    ]
                }*/
                
            ]
        },
        /*{
            "header": "Additional Configuration files (if any)",
            "key": "configfiles",
            "items": [
                {
                    "legend": " ",
                    //"key": "configfiles",
                    "items": [
                        [{
                            "label": "Description",
                            "type": "text",
                            "name": "photos-configfiles-description"
                        },
                        {
                            "label": "File",
                            "type": "file",
                            "accept": ".xlsx, .xls, .doc, .docx, .pdf",
                            "name": "photos-configfiles-files",
                            "info": "Upload configuration as file (Word/PDF/Excel)."
                        }]
                    ]
                }
            ]
        }, */
    ]
}


export const initialFormValues = {
    "transaction": {
        "transactiontype": "Retirement for Sale",
        "txnfab":"",
        "txnmodule":"",
        "txnuemseqptid":"",
        "txnuniquesutlid":"", // for hidden value
        "txn_assets_mgt_status":"", // for hidden value
        "txn_uems_remarks":"", // for hidden value
        "toolid": "",
        "toolstatus": "",
        "fabsection": "",
        "location": "",
        "date": "",
        "toolavailabledate": "",
        "wafersize": "",
        "initiator": ""
    },
    "general": {
        "vendorsupplier": "",
        "model": "",
        "process": "",
        "vintage": "",
        "serialno": "",
        "assetdescription": "",
        "softwareversion": "",
        "cim": "",

    },
    "hardwarefab": {
        "main": [
            {
                "description": "",
                "quantity": "",
                "status": ""
            }
        ],
        "factory": [
            {
                "description": "",
                "quantity": "",
                "status": ""
            }
        ],
        "handler": [
            {
                "description": "",
                "quantity": "",
                "status": ""
            }
        ],
        "options": [
            {
                "description": "",
                "quantity": "",
                "status": ""
            }            
        ],
        "others": [
            {
                "description": "",
                "quantity": "",
                "status": ""
            }
        ],
    },
    "hardwaresubfab": [{
        "description": "NONE",
        "quantity": "",
        "status": ""
    }],
    "missingparts": [{
        "description": "NONE",
        "quantity": ""
    }]

}

export const toolPhotos = {
    "photos": {
        "general": {
            "description": "NONE",
        },
        "hardwarefab": {
            "description": "NONE",
        },
        "hardwaresubfab": {
            "description": "NONE",
        },
        "missingparts": {
            "description": "NONE",
        },
        "manufactureserial":{
            "description":"NONE"
        },
        "configfiles":{
            "description":""
        }
    }
}

export const fileDefaultValues = {
    "photos-general-files": [],
    "photos-hardwarefab-files": [],
    "photos-hardwaresubfab-files": [],
    "photos-missingparts-files": [],
    "photos-manufactureserial-files":[],//, //soban
    "photos-configfiles-files":[] //soban
}
export const requiredFields = [
    "transaction-txnfab",
    "transaction-txnmodule",
    "transaction-txnuemseqptid",
    "transaction-toolid",
    "transaction-toolstatus",
    "transaction-fabsection",
    "transaction-location",
    //"transaction-date",
    "transaction-toolavailabledate",//soban
    "transaction-wafersize",
    "general-vendorsupplier",
    "general-serialno",
    "general-model",
    "general-process",
    "general-vintage",
    "general-assetdescription",
    "general-softwareversion",
    "general-cim",    
    "hardwarefab-main-description",
    "hardwarefab-factory-description",
    "hardwarefab-handler-description"
    // "photos-general-description",
    // "photos-hardwarefab-description",
    // "photos-hardwaresubfab-description",
    // "photos-missingparts-description",
    // "photos-manufactureserial-description",
    // "hardwaresubfab-description",
    // "missingparts-description"
]


export const multiRowSections = ["hardwarefab", "hardwaresubfab", "missingparts"]

export const validations = {
    // moved this validation logic to FormBuilder.jsx
    //"general-vintage":{
    //    "regexp":"198[5-9]|199[0-9]|20[01][0-9]|202[0-3]",
    //    "error":"Please enter year between 1985 and 2023"
    //}
}   
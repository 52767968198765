/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getExcesstransactions = /* GraphQL */ `
  query GetExcesstransactions($id: ID!) {
    getExcesstransactions(id: $id) {
      id
      transactionid
      transactiontype
      toolid
      date
      approved
      toolstatus
      location
      wafersize
      initiator
      reviewer
      reviewerremarks
      fabsection
      toolavailabledate
      lastUpdatedBy
      processed
      txnfab
      txnmodule
      txnuemseqptid
      txnuniquesutlid
      txn_assets_mgt_status
      txn_uems_remarks
      linked_to_deal
      createdAt
      updatedAt
    }
  }
`;
export const listExcesstransactionss = /* GraphQL */ `
  query ListExcesstransactionss(
    $filter: ModelexcesstransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExcesstransactionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionid
        transactiontype
        toolid
        date
        approved
        toolstatus
        location
        wafersize
        initiator
        reviewer
        reviewerremarks
        fabsection
        toolavailabledate
        lastUpdatedBy
        processed
        txnfab
        txnmodule
        txnuemseqptid
        txnuniquesutlid
        txn_assets_mgt_status
        txn_uems_remarks
        linked_to_deal
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExcessgeneral = /* GraphQL */ `
  query GetExcessgeneral($id: ID!, $transactionid: String!) {
    getExcessgeneral(id: $id, transactionid: $transactionid) {
      id
      transactionid
      vendorsupplier
      model
      vintage
      serialno
      assetdescription
      softwareversion
      cim
      process
      processed
      createdAt
      updatedAt
    }
  }
`;
export const listExcessgenerals = /* GraphQL */ `
  query ListExcessgenerals(
    $id: ID
    $transactionid: ModelStringKeyConditionInput
    $filter: ModelexcessgeneralFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExcessgenerals(
      id: $id
      transactionid: $transactionid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionid
        vendorsupplier
        model
        vintage
        serialno
        assetdescription
        softwareversion
        cim
        process
        processed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExcesshardwarefabconfig = /* GraphQL */ `
  query GetExcesshardwarefabconfig($id: ID!, $transactionid: String!) {
    getExcesshardwarefabconfig(id: $id, transactionid: $transactionid) {
      id
      transactionid
      systemtype
      description
      quantity
      status
      processed
      createdAt
      updatedAt
    }
  }
`;
export const listExcesshardwarefabconfigs = /* GraphQL */ `
  query ListExcesshardwarefabconfigs(
    $id: ID
    $transactionid: ModelStringKeyConditionInput
    $filter: ModelexcesshardwarefabconfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExcesshardwarefabconfigs(
      id: $id
      transactionid: $transactionid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionid
        systemtype
        description
        quantity
        status
        processed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExcesshardwaresubfabconfig = /* GraphQL */ `
  query GetExcesshardwaresubfabconfig($id: ID!, $transactionid: String!) {
    getExcesshardwaresubfabconfig(id: $id, transactionid: $transactionid) {
      id
      transactionid
      description
      quantity
      status
      processed
      createdAt
      updatedAt
    }
  }
`;
export const listExcesshardwaresubfabconfigs = /* GraphQL */ `
  query ListExcesshardwaresubfabconfigs(
    $id: ID
    $transactionid: ModelStringKeyConditionInput
    $filter: ModelexcesshardwaresubfabconfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExcesshardwaresubfabconfigs(
      id: $id
      transactionid: $transactionid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionid
        description
        quantity
        status
        processed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExcessmissingfaultyparts = /* GraphQL */ `
  query GetExcessmissingfaultyparts($id: ID!, $transactionid: String!) {
    getExcessmissingfaultyparts(id: $id, transactionid: $transactionid) {
      id
      transactionid
      description
      quantity
      processed
      createdAt
      updatedAt
    }
  }
`;
export const listExcessmissingfaultypartss = /* GraphQL */ `
  query ListExcessmissingfaultypartss(
    $id: ID
    $transactionid: ModelStringKeyConditionInput
    $filter: ModelexcessmissingfaultypartsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExcessmissingfaultypartss(
      id: $id
      transactionid: $transactionid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionid
        description
        quantity
        processed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExcesstoolpictures = /* GraphQL */ `
  query GetExcesstoolpictures($id: ID!, $transactionid: String!) {
    getExcesstoolpictures(id: $id, transactionid: $transactionid) {
      id
      transactionid
      systemtype
      description
      toolpicture
      toolfilename
      mimetype
      md5
      processed
      createdAt
      updatedAt
    }
  }
`;
export const listExcesstoolpicturess = /* GraphQL */ `
  query ListExcesstoolpicturess(
    $id: ID
    $transactionid: ModelStringKeyConditionInput
    $filter: ModelexcesstoolpicturesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExcesstoolpicturess(
      id: $id
      transactionid: $transactionid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionid
        systemtype
        description
        toolpicture
        toolfilename
        mimetype
        md5
        processed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExcessuserroles = /* GraphQL */ `
  query GetExcessuserroles($id: ID!) {
    getExcessuserroles(id: $id) {
      id
      email
      role
      submitlist
      approvelist
      reportlist
      createdAt
      updatedAt
    }
  }
`;
export const listExcessuserroless = /* GraphQL */ `
  query ListExcessuserroless(
    $filter: ModelexcessuserrolesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExcessuserroless(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        role
        submitlist
        approvelist
        reportlist
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExcesstransactionslogger = /* GraphQL */ `
  query GetExcesstransactionslogger($id: ID!, $loggeddate: AWSDate!) {
    getExcesstransactionslogger(id: $id, loggeddate: $loggeddate) {
      id
      level
      loggeddate
      message
      createdAt
      updatedAt
    }
  }
`;
export const listExcesstransactionsloggers = /* GraphQL */ `
  query ListExcesstransactionsloggers(
    $id: ID
    $loggeddate: ModelStringKeyConditionInput
    $filter: ModelexcesstransactionsloggerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExcesstransactionsloggers(
      id: $id
      loggeddate: $loggeddate
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        level
        loggeddate
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExcesslocations = /* GraphQL */ `
  query GetExcesslocations($id: ID!) {
    getExcesslocations(id: $id) {
      id
      location
      enable
      createdAt
      updatedAt
    }
  }
`;
export const listExcesslocationss = /* GraphQL */ `
  query ListExcesslocationss(
    $filter: ModelexcesslocationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExcesslocationss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        location
        enable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExcessuemsdata = /* GraphQL */ `
  query GetExcessuemsdata($id: ID!) {
    getExcessuemsdata(id: $id) {
      id
      fab
      module
      uems_eqp_id
      unique_id
      supplier
      asset_mgt_status
      txn_uems_remarks
      createdAt
      updatedAt
    }
  }
`;
export const listExcessuemsdatas = /* GraphQL */ `
  query ListExcessuemsdatas(
    $filter: ModelexcessuemsdataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExcessuemsdatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fab
        module
        uems_eqp_id
        unique_id
        supplier
        asset_mgt_status
        txn_uems_remarks
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExcessbrokerconfig = /* GraphQL */ `
  query GetExcessbrokerconfig($id: ID!) {
    getExcessbrokerconfig(id: $id) {
      id
      broker
      enable
      createdAt
      updatedAt
    }
  }
`;
export const listExcessbrokerconfigs = /* GraphQL */ `
  query ListExcessbrokerconfigs(
    $filter: ModelexcessbrokerconfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExcessbrokerconfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        broker
        enable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExcesstoolsalestransactions = /* GraphQL */ `
  query GetExcesstoolsalestransactions($id: ID!) {
    getExcesstoolsalestransactions(id: $id) {
      id
      transactionid
      transactiontype
      broker
      buyer
      date
      initiator
      l3_fab_approver
      l3_fin_approver
      status
      comment
      rejectedreason
      total_price
      total_nbv
      total_gain_loss
      nondeclared
      deal_ref
      last_updated_by
      createdAt
      updatedAt
    }
  }
`;
export const listExcesstoolsalestransactionss = /* GraphQL */ `
  query ListExcesstoolsalestransactionss(
    $filter: ModelexcesstoolsalestransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExcesstoolsalestransactionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionid
        transactiontype
        broker
        buyer
        date
        initiator
        l3_fab_approver
        l3_fin_approver
        status
        comment
        rejectedreason
        total_price
        total_nbv
        total_gain_loss
        nondeclared
        deal_ref
        last_updated_by
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExcesstoolsalestooldetails = /* GraphQL */ `
  query GetExcesstoolsalestooldetails($id: ID!, $transactionid: String!) {
    getExcesstoolsalestooldetails(id: $id, transactionid: $transactionid) {
      id
      transactionid
      toolid
      fab
      location
      vendor
      model
      serialnum
      price
      nbv
      sales_type
      asset_mgt_status
      remarks
      no_internal_reuse
      removal_path_clear
      no_trade_in
      declared_type
      deal_ref
      createdAt
      updatedAt
    }
  }
`;
export const listExcesstoolsalestooldetailss = /* GraphQL */ `
  query ListExcesstoolsalestooldetailss(
    $id: ID
    $transactionid: ModelStringKeyConditionInput
    $filter: ModelexcesstoolsalestooldetailsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExcesstoolsalestooldetailss(
      id: $id
      transactionid: $transactionid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionid
        toolid
        fab
        location
        vendor
        model
        serialnum
        price
        nbv
        sales_type
        asset_mgt_status
        remarks
        no_internal_reuse
        removal_path_clear
        no_trade_in
        declared_type
        deal_ref
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExcesstoolsalesfilesdata = /* GraphQL */ `
  query GetExcesstoolsalesfilesdata($id: ID!, $transactionid: String!) {
    getExcesstoolsalesfilesdata(id: $id, transactionid: $transactionid) {
      id
      transactionid
      description
      name
      mimetype
      url
      createdAt
      updatedAt
    }
  }
`;
export const listExcesstoolsalesfilesdatas = /* GraphQL */ `
  query ListExcesstoolsalesfilesdatas(
    $id: ID
    $transactionid: ModelStringKeyConditionInput
    $filter: ModelexcesstoolsalesfilesdataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExcesstoolsalesfilesdatas(
      id: $id
      transactionid: $transactionid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionid
        description
        name
        mimetype
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExcesstoolsalesapprovals = /* GraphQL */ `
  query GetExcesstoolsalesapprovals($id: ID!) {
    getExcesstoolsalesapprovals(id: $id) {
      id
      transactionid
      l1
      l1_time
      l2
      l2_time
      l3
      l3_time
      l4
      l4_time
      l5
      l5_time
      l1_reminders
      l2_reminders
      l3_reminders
      l4_reminders
      rejetedlevel
      rejectedby
      cancelledby
      createdAt
      updatedAt
    }
  }
`;
export const listExcesstoolsalesapprovalss = /* GraphQL */ `
  query ListExcesstoolsalesapprovalss(
    $filter: ModelexcesstoolsalesapprovalsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExcesstoolsalesapprovalss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionid
        l1
        l1_time
        l2
        l2_time
        l3
        l3_time
        l4
        l4_time
        l5
        l5_time
        l1_reminders
        l2_reminders
        l3_reminders
        l4_reminders
        rejetedlevel
        rejectedby
        cancelledby
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
